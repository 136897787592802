
import { Vue, Component } from "nuxt-property-decorator";
import ViewAvatarUser from "~/components/ViewAvatarUser.vue";
import IcEmoji from "~/assets/icons/IcEmoji.vue";
import IcImage from "~/assets/icons/IcImage.vue";
import IcVideo from "~/assets/icons/IcVideo.vue";

@Component({
  components: {
    ViewAvatarUser,
    IcEmoji,
    IcImage,
    IcVideo,
  },
})
export default class NewQuestion extends Vue {
  get languageCode() {
    return this.$auth.loggedIn
      ? this.$auth.user.support_language.code
      : this.$i18n.locale;
  }
}
