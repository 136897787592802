var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "new-question" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c("view-avatar-user", {
          attrs: {
            avatar: _vm.$store.state.userInfo.userInfo.avatar,
            width: "40px",
            height: "40px",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "placeholder-content d-flex flex-1 justify-content-between cursor-pointer",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("add-new-question")
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("forums.your_question")))]),
            _vm._v(" "),
            _c("IcEmoji"),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "line-separative" }),
    _vm._v(" "),
    _c("div", { staticClass: "icons-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center cursor-pointer",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("add-new-question")
            },
          },
        },
        [
          _c("IcImage"),
          _vm._v(" "),
          _c("span", { staticClass: "ml-1" }, [_vm._v("Images")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center cursor-pointer",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("add-new-question")
            },
          },
        },
        [
          _c("IcVideo"),
          _vm._v(" "),
          _c("span", { staticClass: "ml-1" }, [_vm._v("Videos")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }