import { render, staticRenderFns } from "./AddQuestion.vue?vue&type=template&id=013eb036&scoped=true"
import script from "./AddQuestion.vue?vue&type=script&lang=ts"
export * from "./AddQuestion.vue?vue&type=script&lang=ts"
import style0 from "./AddQuestion.vue?vue&type=style&index=0&id=013eb036&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013eb036",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bao/goemon-community-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('013eb036')) {
      api.createRecord('013eb036', component.options)
    } else {
      api.reload('013eb036', component.options)
    }
    module.hot.accept("./AddQuestion.vue?vue&type=template&id=013eb036&scoped=true", function () {
      api.rerender('013eb036', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/forums/AddQuestion.vue"
export default component.exports