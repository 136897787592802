
import { Component, mixins, Vue } from "nuxt-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Common, Forum } from "~/modals";
import CommonMixins from "~/mixins/CommonMixins.vue";
import { plainToClass } from "class-transformer";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { Tippy } from "vue-tippy";
import QuestionFeaturedItem from "./QuestionFeaturedItem.vue";

@Component({
  components: {
    PerfectScrollbar,
    ToMoreInfo: () => import("./ToMoreInfo.vue"),
    QuestionFeaturedItem,
    // QuestionFeaturedItem: () => import("./QuestionFeaturedItem.vue"),
    Tippy,
  },
})
export default class MenuRight extends mixins<CommonMixins>(CommonMixins) {
  hashTags: Common[] = [];
  topQuestions: Forum[] = [];
  isWheelPropagation = true;
  textContent: string = "";
  async fetch() {
    try {
      const hashtag = this.$axios.$get(`api/v1/tags/top`, {
        params: {
          language_code: this.$auth.loggedIn
            ? this.$auth.user.support_language.code
            : this.$i18n.locale,
        },
      });
      const topQuestion = this.$axios.$get(`api/v1/communities/top`, {
        params: {
          language_code: this.$auth.loggedIn
            ? this.$auth.user.support_language.code
            : this.$i18n.locale,
        },
      });
      const rs = await Promise.all([hashtag, topQuestion]);
      const [objHashTag, objTopQuestion] = rs;
      // this.hashTags = this.filteredArrayLanguageCode(this.cleanSource(this.$store.state.tag.hashTags), this.$i18n.locale)?.filter(
      //       _o => objHashTag['data']?.some(id => id === _o.id)) ?? [];
      let hashtagList =
        this.filteredArrayLanguageCode(
          this.cleanSource(this.$store.state.tag.hashTags),
          this.$i18n.locale
        ) ?? [];
      let arrFilter = [];
      objHashTag["data"]?.forEach((hashTagId) => {
        let hashTagFilter = hashtagList?.find((_o) => _o.id === hashTagId);
        if (hashTagFilter) {
          (arrFilter as any).push(hashTagFilter);
        }
      });
      this.hashTags = arrFilter;
      const questions = plainToClass(Forum, objTopQuestion["data"] as []);
      const fetchProfile = () => {
        let _count = 0;
        return new Promise((resolve, reject) => {
          try {
            if (!questions.length) resolve(true);
            questions.forEach(async (_o) => {
              const author = await this.$store.dispatch(
                "profile/fetchNewProfileById",
                _o.authorId
              );
              _o.author = author;
              _count++;
              if (_count === questions.length) {
                resolve(true);
              }
            });
          } catch (error) {
            reject(error);
          }
        });
      };
      await fetchProfile();
      this.topQuestions = questions;
    } catch (error) {
      //TODO
    }
  }

  changeWheelPropagation() {
    this.$nextTick(() => {
      const chats = this.$refs["menu"] as Vue;
      const scrollbarYActive = (chats as any)?.ps?.scrollbarYActive ?? false;
      this.isWheelPropagation == scrollbarYActive &&
        (this.isWheelPropagation = !scrollbarYActive);
    });
  }
  mounted() {
    this.changeWheelPropagation();
  }
  updated() {
    this.changeWheelPropagation();
  }
  gotoHashTag(hashtag) {
    return (this as any).localePath({
      name: "forums-tags",
      query: { hashtag_ids: hashtag.id, hash_tag_name: hashtag.name },
    });
  }

  activated() {
    // Call fetch again if last fetch more than a minute ago
    if (this.$fetchState.timestamp <= Date.now() - 30000) {
      this.$fetch();
    }
  }
}
