var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "wrapper_question_" + _vm.question.id,
      staticClass: "wrapper-question",
    },
    [
      _vm.checkHideQuestion
        ? _c("div", { staticClass: "hide-question position-relative" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$route.name.includes("forums-slug"),
                    expression: "!$route.name.includes('forums-slug')",
                  },
                ],
                staticClass: "position-absolute icon-close",
                on: {
                  click: function ($event) {
                    return _vm.onRemoveQuestion()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("assets/icons/icon-close.svg"),
                    alt: "",
                    srcset: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "mr-3" }, [
              _vm._v("\n      " + _vm._s(_vm.hideBlockContent) + "\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.checkHideQuestion
        ? _c(
            "div",
            {
              staticClass: "list-question",
              class: { border_bottom: !_vm.question.isViewComment },
              attrs: { id: "tag_" + _vm.question.id },
            },
            [
              _c(
                "div",
                { staticClass: "px-3 title-wrapper" },
                [
                  _c("div", { staticClass: "d-tag-smaller-md-size" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        attrs: { id: "tag_name_" + _vm.question.id },
                      },
                      _vm._l(_vm.tagName, function (tag, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "d-inline tag__name",
                            class: [{ "mr-3": i !== _vm.tagName.length - 1 }],
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      delay: [1000, 200],
                                      placement: "bottom-start",
                                      animateFill: false,
                                      animation: "scale",
                                      zIndex:
                                        _vm.textContent === "" ? -1 : 9999,
                                    },
                                    expression:
                                      "{\n                delay: [1000, 200],\n                placement: 'bottom-start',\n                animateFill: false,\n                animation: 'scale',\n                zIndex: textContent === '' ? -1 : 9999,\n              }",
                                  },
                                ],
                                attrs: {
                                  content: `<span style='word-break: break-word;'>
              ${_vm.tagName[0].name}
            </span>`,
                                },
                                on: {
                                  contextmenu: function ($event) {
                                    return (() => {
                                      _vm.textContent = ""
                                    }).apply(null, arguments)
                                  },
                                  mouseover: () => {
                                    _vm.textContent = _vm.$handleHover(
                                      "tag_name_" + _vm.question.id,
                                      _vm.tagName[0].name,
                                      1,
                                      14,
                                      0,
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.tagName[0].name))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "divided my-3" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "question-header d-flex justify-content-between w-100",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-shrink-0 align-items-start",
                        },
                        [
                          _c(
                            "nuxt-link",
                            {
                              staticClass:
                                "d-block image-avatar cursor-pointer",
                              class: [
                                {
                                  "cursor-auto pe-none":
                                    _vm.$auth.user &&
                                    _vm.question?.author?.propertyID ===
                                      _vm.$auth.user.property_id,
                                },
                                {
                                  "cursor-default":
                                    _vm.$route.hash == "#question" ||
                                    _vm.question.author.userSystem,
                                },
                              ],
                              attrs: {
                                event:
                                  _vm.$route.hash == "#question" ||
                                  _vm.question.author.userSystem
                                    ? ""
                                    : "click",
                                to: _vm.$goToDetailCandidate(
                                  _vm.question?.author?.name ?? "",
                                  _vm.question?.author?.propertyID ?? ""
                                ),
                              },
                            },
                            [
                              _c("nuxt-img", {
                                attrs: {
                                  format: "webp",
                                  width: "40",
                                  height: "40",
                                  src: _vm.authorAvatar,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "owner flex-1 ml-2" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$isIE,
                                      expression: "!$isIE",
                                    },
                                  ],
                                  staticClass: "name w-100",
                                  class: {
                                    "d-flex align-items-center": !_vm.$isIE,
                                  },
                                },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "d-inline",
                                      class: [
                                        {
                                          "cursor-auto pe-none":
                                            _vm.$auth.user &&
                                            _vm.question?.author?.propertyID ===
                                              _vm.$auth.user.property_id,
                                        },
                                        ,
                                        {
                                          "cursor-default":
                                            _vm.$route.hash == "#question" ||
                                            _vm.question.author.userSystem,
                                        },
                                      ],
                                      attrs: {
                                        event:
                                          _vm.$route.hash == "#question" ||
                                          _vm.question.author.userSystem
                                            ? ""
                                            : "click",
                                        to: _vm.$goToDetailCandidate(
                                          _vm.question?.author?.name ?? "",
                                          _vm.question?.author?.propertyID ?? ""
                                        ),
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.authorName))]
                                  ),
                                  _vm._v(" "),
                                  _vm.question.author.userSystem
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy",
                                              value: {
                                                delay: [1000, 200],
                                                placement: "bottom-start",
                                                animateFill: false,
                                                animation: "scale",
                                                allowHTML: true,
                                                theme: "light",
                                                zIndex: _vm.isRightClick
                                                  ? -1
                                                  : 9999,
                                              },
                                              expression:
                                                "{\n                  delay: [1000, 200],\n                  placement: 'bottom-start',\n                  animateFill: false,\n                  animation: 'scale',\n                  allowHTML: true,\n                  theme: 'light',\n                  zIndex: isRightClick ? -1 : 9999,\n                }",
                                            },
                                          ],
                                          staticClass: "ml-1",
                                          attrs: {
                                            content: `<div style='width:198px; text-align: left;'><span style='font-style: normal;font-weight: 600;font-size: 11px;line-height: 18px;letter-spacing: 0.02em;color: $body-text;'>${_vm.$t(
                                              "common.user_system_tooltip"
                                            )}</span></div>`,
                                          },
                                          on: {
                                            contextmenu: function ($event) {
                                              _vm.isRightClick = true
                                            },
                                            mouseover: function ($event) {
                                              _vm.isRightClick = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "20",
                                                height: "20",
                                                viewBox: "0 0 20 20",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5C8.01088 17.5 6.10322 16.7098 4.6967 15.3033C3.29018 13.8968 2.5 11.9891 2.5 10H2.5ZM9.572 13.21L13.89 7.812L13.11 7.188L9.428 11.789L6.82 9.616L6.18 10.384L9.572 13.211V13.21Z",
                                                  fill: "#0960BD",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("client-only", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$isIE,
                                        expression: "$isIE",
                                      },
                                    ],
                                    class: {
                                      "d-flex w-100 align-items-center":
                                        _vm.$isIE,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-clamp",
                                      {
                                        staticClass: "name",
                                        attrs: {
                                          autoresize: "",
                                          "max-lines": 2,
                                          tag: "div",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.authorName) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.question.author.userSystem
                                      ? _c("span", { staticClass: "ml-1" }, [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "20",
                                                height: "20",
                                                viewBox: "0 0 20 20",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5C8.01088 17.5 6.10322 16.7098 4.6967 15.3033C3.29018 13.8968 2.5 11.9891 2.5 10H2.5ZM9.572 13.21L13.89 7.812L13.11 7.188L9.428 11.789L6.82 9.616L6.18 10.384L9.572 13.211V13.21Z",
                                                  fill: "#0960BD",
                                                },
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.authorName,
                                      expression: "authorName",
                                    },
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        delay: [1000, 200],
                                        placement: "bottom-start",
                                        animateFill: false,
                                        animation: "scale",
                                        allowHTML: true,
                                        zIndex: _vm.isRightClick ? -1 : 9999,
                                      },
                                      expression:
                                        "{\n                delay: [1000, 200],\n                placement: 'bottom-start',\n                animateFill: false,\n                animation: 'scale',\n                allowHTML: true,\n                zIndex: isRightClick ? -1 : 9999,\n              }",
                                    },
                                  ],
                                  key: _vm.currentTime,
                                  staticClass: "created-at cursor-default",
                                  attrs: {
                                    content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeForum(
                                      _vm.question.createdAt
                                    )}</span>`,
                                  },
                                  on: {
                                    contextmenu: function ($event) {
                                      _vm.isRightClick = true
                                    },
                                    mouseover: function ($event) {
                                      _vm.isRightClick = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.displayCreateTime(
                                          _vm.question.createdAt
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tag d-tag-bigger-md-size",
                          attrs: { id: "tag_name_" + _vm.question.id },
                        },
                        _vm._l(_vm.tagName, function (tag, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "d-inline tag__name",
                              class: [{ "mr-3": i !== _vm.tagName.length - 1 }],
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        delay: [1000, 200],
                                        placement: "bottom-start",
                                        animateFill: false,
                                        animation: "scale",
                                        zIndex:
                                          _vm.textContent === "" ? -1 : 9999,
                                      },
                                      expression:
                                        "{\n                delay: [1000, 200],\n                placement: 'bottom-start',\n                animateFill: false,\n                animation: 'scale',\n                zIndex: textContent === '' ? -1 : 9999,\n              }",
                                    },
                                  ],
                                  attrs: {
                                    content: `<span style='word-break: break-word;'>
                  ${_vm.tagName[0].name}
                </span>`,
                                  },
                                  on: {
                                    contextmenu: function ($event) {
                                      return (() => {
                                        _vm.textContent = ""
                                      }).apply(null, arguments)
                                    },
                                    mouseover: () => {
                                      _vm.textContent = _vm.$handleHover(
                                        "tag_name_" + _vm.question.id,
                                        _vm.tagName[0].name,
                                        1,
                                        14,
                                        0,
                                        null
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.tagName[0].name))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "_title" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.question.title))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "content_" + _vm.question.id,
                      staticClass: "_content",
                    },
                    [
                      _c("read-more-content-2", {
                        key: _vm.question.id,
                        attrs: {
                          id_show_less: "tag_" + _vm.question.id,
                          content: _vm.question.content,
                        },
                        on: { hide: _vm.onHide },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hash-tag-question", {
                    staticClass: "mt-1",
                    attrs: { hashTags: _vm.question.hashTags },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              Array.isArray(_vm.question.images) && _vm.question.images.length
                ? _c("grid-gallery-image", {
                    staticClass: "mt-3 grid-image-wrapper",
                    attrs: { images: _vm.question.images },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "px-3 action-wrapper" }, [
                _c("div", { staticClass: "divided" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "group-btn-action d-flex align-items-center justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between no-select",
                      },
                      [
                        _c("div", { staticClass: "d-flex vote-wrapper mr-3" }, [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center",
                              on: {
                                click: () => {
                                  _vm.$auth.user && !_vm.$auth.user.property_id
                                    ? ((_vm.confirmDialogContent = _vm
                                        .$t(
                                          "forums.please_update_profile_to_vote"
                                        )
                                        .toString()),
                                      (_vm.showConfirmDialog = true))
                                    : _vm.likeQuestion()
                                },
                              },
                            },
                            [
                              !_vm.question.liked
                                ? _c("IcUpvote", {
                                    staticClass: "cursor-pointer",
                                  })
                                : _c("IcUpvoteFullFill", {
                                    staticClass: "cursor-pointer",
                                  }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "d-tag-bigger-md-size ml-1" },
                                [_vm._v(_vm._s(_vm.$t("forums.upvote")))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex",
                              on: {
                                click: function ($event) {
                                  _vm.$auth.user && !_vm.$auth.user.property_id
                                    ? ((_vm.confirmDialogContent = _vm
                                        .$t(
                                          "forums.please_update_profile_to_vote"
                                        )
                                        .toString()),
                                      (_vm.showConfirmDialog = true))
                                    : _vm.likeQuestion()
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.question.totalLike)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "1",
                                    height: "16",
                                    viewBox: "0 0 1 16",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      width: "1",
                                      height: "16",
                                      fill: "#CDD6DF",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center",
                              on: {
                                click: function ($event) {
                                  _vm.$auth.user && !_vm.$auth.user.property_id
                                    ? ((_vm.confirmDialogContent = _vm
                                        .$t(
                                          "forums.please_update_profile_to_vote"
                                        )
                                        .toString()),
                                      (_vm.showConfirmDialog = true))
                                    : _vm.downVote()
                                },
                              },
                            },
                            [
                              !_vm.question.disliked
                                ? _c("IcDownvote", {
                                    staticClass: "cursor-pointer",
                                  })
                                : _c("IcDownvoteFullFill", {
                                    staticClass: "cursor-pointer",
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.$auth.user && !_vm.$auth.user.property_id
                          ? _c(
                              "div",
                              {
                                staticClass: "d-flex btn-share",
                                on: {
                                  click: function ($event) {
                                    ;(_vm.confirmDialogContent = _vm
                                      .$t("authentication.update_profile_warn")
                                      .toString()),
                                      (_vm.showConfirmDialog = true)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex mr-1 align-items-center",
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 20 20",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M4.9 3.97934C4.39609 3.97934 3.91282 4.17538 3.5565 4.52433C3.20018 4.87329 3 5.34657 3 5.84007V11.912C3 12.4055 3.20018 12.8787 3.5565 13.2277C3.91282 13.5766 4.39609 13.7727 4.9 13.7727H6.2V15.9261L10.6412 13.7727H15.1C15.6039 13.7727 16.0872 13.5766 16.4435 13.2277C16.7998 12.8787 17 12.4055 17 11.912V5.84007C17 5.34657 16.7998 4.87329 16.4435 4.52433C16.0872 4.17538 15.6039 3.97934 15.1 3.97934H4.9ZM2 5.84007C2 5.08684 2.30553 4.36446 2.84939 3.83184C3.39325 3.29922 4.13087 3 4.9 3H15.1C15.8691 3 16.6068 3.29922 17.1506 3.83184C17.6945 4.36446 18 5.08684 18 5.84007V11.912C18 12.6652 17.6945 13.3876 17.1506 13.9202C16.6068 14.4528 15.8691 14.752 15.1 14.752H10.9588L6.5 16.9205C6.5 16.9205 5.2 17.4176 5.2 15.9261V14.752H4.9C4.13087 14.752 3.39325 14.4528 2.84939 13.9202C2.30553 13.3876 2 12.6652 2 11.912V5.84007Z",
                                            fill: "#525252",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.question.totalComment)),
                                  ]),
                                ]),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "d-flex btn-share",
                                on: { click: _vm.viewCommentOfQuestion },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex mr-1 align-items-center",
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 20 20",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M4.9 3.97934C4.39609 3.97934 3.91282 4.17538 3.5565 4.52433C3.20018 4.87329 3 5.34657 3 5.84007V11.912C3 12.4055 3.20018 12.8787 3.5565 13.2277C3.91282 13.5766 4.39609 13.7727 4.9 13.7727H6.2V15.9261L10.6412 13.7727H15.1C15.6039 13.7727 16.0872 13.5766 16.4435 13.2277C16.7998 12.8787 17 12.4055 17 11.912V5.84007C17 5.34657 16.7998 4.87329 16.4435 4.52433C16.0872 4.17538 15.6039 3.97934 15.1 3.97934H4.9ZM2 5.84007C2 5.08684 2.30553 4.36446 2.84939 3.83184C3.39325 3.29922 4.13087 3 4.9 3H15.1C15.8691 3 16.6068 3.29922 17.1506 3.83184C17.6945 4.36446 18 5.08684 18 5.84007V11.912C18 12.6652 17.6945 13.3876 17.1506 13.9202C16.6068 14.4528 15.8691 14.752 15.1 14.752H10.9588L6.5 16.9205C6.5 16.9205 5.2 17.4176 5.2 15.9261V14.752H4.9C4.13087 14.752 3.39325 14.4528 2.84939 13.9202C2.30553 13.3876 2 12.6652 2 11.912V5.84007Z",
                                            fill: "#525252",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.question.totalComment)),
                                  ]),
                                ]),
                              ]
                            ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.onCloseSocial,
                                expression: "onCloseSocial",
                              },
                            ],
                            staticClass: "share-social-group d-flex",
                            attrs: { id: "btn_social" },
                            on: { click: _vm.openSocial },
                          },
                          [
                            _c("div", {}, [_c("IcShare")], 1),
                            _vm._v(" "),
                            _vm.question.isSocial
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "group-menu-social",
                                    attrs: { id: "share_social_network" },
                                  },
                                  [
                                    _c("share-social-network", {
                                      attrs: { question: _vm.question },
                                      on: {
                                        "clipboard-success": function ($event) {
                                          _vm.question.isSocial =
                                            !_vm.question.isSocial
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.onCloseMore,
                                expression: "onCloseMore",
                              },
                            ],
                            staticClass: "more d-flex",
                            attrs: { id: "btn_more" },
                            on: { click: _vm.openMore },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "no-select",
                                attrs: {
                                  width: "20",
                                  height: "20",
                                  viewBox: "0 0 20 20",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M6 10C6 10.5304 5.78929 11.0391 5.41421 11.4142C5.03914 11.7893 4.53043 12 4 12C3.46957 12 2.96086 11.7893 2.58579 11.4142C2.21071 11.0391 2 10.5304 2 10C2 9.46957 2.21071 8.96086 2.58579 8.58579C2.96086 8.21071 3.46957 8 4 8C4.53043 8 5.03914 8.21071 5.41421 8.58579C5.78929 8.96086 6 9.46957 6 10Z",
                                    fill: "#525252",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M12 10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8C10.5304 8 11.0391 8.21071 11.4142 8.58579C11.7893 8.96086 12 9.46957 12 10Z",
                                    fill: "#525252",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M16 12C16.5304 12 17.0391 11.7893 17.4142 11.4142C17.7893 11.0391 18 10.5304 18 10C18 9.46957 17.7893 8.96086 17.4142 8.58579C17.0391 8.21071 16.5304 8 16 8C15.4696 8 14.9609 8.21071 14.5858 8.58579C14.2107 8.96086 14 9.46957 14 10C14 10.5304 14.2107 11.0391 14.5858 11.4142C14.9609 11.7893 15.4696 12 16 12Z",
                                    fill: "#525252",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.question.isMoreOwner
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "group-more",
                                    class: [
                                      {
                                        "group-more__owner":
                                          _vm.$auth.loggedIn &&
                                          _vm.$auth.user.id ===
                                            _vm.question.authorId,
                                      },
                                    ],
                                    attrs: { id: "more_option_owner" },
                                  },
                                  [
                                    _c("more-option-owner", {
                                      attrs: {
                                        isButtonDelete:
                                          _vm.question.isButtonDelete &&
                                          _vm.$auth.loggedIn &&
                                          _vm.$auth.user.id ===
                                            _vm.question.authorId,
                                        isButtonEdit:
                                          _vm.question.isButtonEdit &&
                                          _vm.$auth.loggedIn &&
                                          _vm.$auth.user.id ===
                                            _vm.question.authorId,
                                        isButtonReport:
                                          !_vm.$auth.loggedIn ||
                                          _vm.$auth.user.id !==
                                            _vm.question.authorId,
                                        isButtonBookmark: _vm.$auth.loggedIn,
                                        isButtonHide:
                                          _vm.$auth.loggedIn &&
                                          _vm.$auth.user.id !==
                                            _vm.question.authorId,
                                        bookmarked: _vm.question.isBookmark,
                                        name: _vm.authorName,
                                      },
                                      on: {
                                        edit: _vm.openEdit,
                                        delete: function ($event) {
                                          return _vm.$emit(
                                            "delete-question",
                                            _vm.question.id
                                          )
                                        },
                                        report: function ($event) {
                                          return _vm.onReport(_vm.question.id)
                                        },
                                        bookmark: _vm.onBookmark,
                                        "show-confirm-dialog": () => {
                                          _vm.confirmDialogContent = _vm
                                            .$t(
                                              "authentication.update_profile_warn"
                                            )
                                            .toString()
                                          _vm.showConfirmDialog = true
                                        },
                                        hide: function ($event) {
                                          _vm.showConfirmHideQuestion = true
                                        },
                                        "hide-from-user": function ($event) {
                                          ;(_vm.showConfirmBlockUser = true),
                                            _vm.$emit(
                                              "confirm-modal",
                                              _vm.hideQuestionFromUser,
                                              _vm.showConfirmBlockUser
                                            )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.checkHideQuestion
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.$route.path.includes(_vm.localePath("/candidates")) ||
                    _vm.question.isViewComment,
                  expression:
                    "\n      !$route.path.includes(localePath('/candidates')) ||\n      question.isViewComment\n    ",
                },
              ],
              staticClass: "group-comment",
            },
            [
              _c("view-comment", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShow,
                    expression: "isShow",
                  },
                ],
                ref: "view_comment",
                attrs: {
                  questionId: _vm.question.id,
                  question: _vm.question,
                  lastChild: _vm.$attrs.lastChild,
                  isVisible: _vm.checkIsVisible,
                },
                on: {
                  "remove-question": function ($event) {
                    return _vm.$emit("remove-question", _vm.question.id)
                  },
                  "report-comment": _vm.reportComment,
                  "show-confirm-dialog": () => {
                    _vm.confirmDialogContent = _vm
                      .$t("forums.please_update_profile_to_vote")
                      .toString()
                    _vm.showConfirmDialog = true
                  },
                  "show-confirm-dialog-comment": () => {
                    _vm.confirmDialogContent = _vm
                      .$t("forums.please_update_profile_to_answer")
                      .toString()
                    _vm.showConfirmDialog = true
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.question.isEditData
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.question.isEditData,
                  expression: "question.isEditData",
                },
              ],
              key: _vm.question.id,
            },
            [
              _c("add-question", {
                attrs: { editOrNot: true, questionId: _vm.question.id },
                on: {
                  close: function ($event) {
                    _vm.question.isEditData = false
                    _vm.$emit("edit-question", false)
                  },
                  "remove-question": function ($event) {
                    _vm.$emit("remove-question", _vm.question.id)
                    _vm.question.isEditData = false
                  },
                  "edit-question-success": function ($event) {
                    return _vm.$emit("edit-question-success", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.warningLikeQuestion
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.warningLikeQuestion,
                  expression: "warningLikeQuestion",
                },
              ],
              key: _vm.question.id,
              on: {
                "close-modal": function ($event) {
                  _vm.warningLikeQuestion = false
                },
              },
            },
            [
              _c("dialog-confirm", {
                attrs: { content: _vm.messageConfirm },
                on: {
                  "click-yes": _vm.goToLogin,
                  "click-no": function ($event) {
                    _vm.warningLikeQuestion = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmDialog
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmDialog,
                  expression: "showConfirmDialog",
                },
              ],
            },
            [
              _c("dialog-confirm", {
                attrs: {
                  title: " ",
                  content: _vm.confirmDialogContent,
                  labelButtonYes: _vm.$t("common.update"),
                  labelButtonNo: _vm.$t("common.later"),
                },
                on: {
                  "click-yes": _vm.fnYes,
                  "click-no": function ($event) {
                    _vm.showConfirmDialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showErrorDialog
        ? _c(
            "portal",
            { attrs: { to: "modal-outlet" } },
            [
              _c(
                "full-modal",
                {
                  directives: [
                    {
                      name: "scroll-lock",
                      rawName: "v-scroll-lock",
                      value: _vm.showErrorDialog,
                      expression: "showErrorDialog",
                    },
                  ],
                },
                [
                  _c("dialog-error", {
                    attrs: {
                      title: _vm.$t("common.error"),
                      content: _vm.errorContent,
                      button: _vm.$t("common.ok"),
                    },
                    on: {
                      "click-yes": function ($event) {
                        return _vm.onClickYes($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReportDetail
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showReportDetail,
                  expression: "showReportDetail",
                },
              ],
            },
            [
              _c("report-detail", {
                attrs: { report: _vm.report, reportTitle: _vm.reportTitle },
                on: {
                  "close-report-detail": function ($event) {
                    return _vm.closeReportDetail()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmHideQuestion
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmHideQuestion,
                  expression: "showConfirmHideQuestion",
                },
              ],
              staticClass: "w-100",
            },
            [
              _c("confirm-dialog", {
                attrs: {
                  title: _vm.$t("entry_sheet.comfirm"),
                  content: _vm.$t("forums.confirm_hide_question"),
                },
                on: {
                  "click-yes": _vm.hideQuestion,
                  "click-no": function ($event) {
                    _vm.showConfirmHideQuestion = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmBlockUser
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmBlockUser,
                  expression: "showConfirmBlockUser",
                },
              ],
            },
            [
              _c("confirm-dialog", {
                attrs: {
                  title: _vm.$t("entry_sheet.comfirm"),
                  content: _vm
                    .$t("report.hide_all_content_from_confirm")
                    .toString()
                    .replace("_name_", _vm.authorName),
                },
                on: {
                  "click-yes": _vm.hideQuestionFromUser,
                  "click-no": function ($event) {
                    _vm.showConfirmBlockUser = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }