var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    {
      ref: "menu",
      staticClass: "wrapper-menu",
      attrs: {
        options: { wheelPropagation: _vm.isWheelPropagation },
        "watch-options": true,
        tag: "div",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.$auth.loggedIn,
              expression: "!!$auth.loggedIn",
            },
          ],
          staticClass: "my-info",
        },
        [
          _vm.$auth.loggedIn
            ? _c(
                "div",
                { staticClass: "my-info__first d-flex" },
                [
                  _c("view-avatar-user", {
                    attrs: {
                      avatar: _vm.$store.state.userInfo.userInfo.avatar,
                      width: "58px",
                      height: "58px",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-2 d-flex flex-column flex-1" }, [
                    _c(
                      "span",
                      {
                        staticClass: "IE-align-center my-info__first__name",
                        class: [{ "d-block": _vm.$isIE }],
                      },
                      [_vm._v(_vm._s(_vm.$auth.user.name))]
                    ),
                    _vm._v(" "),
                    _vm.userProfile?.department
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "IE-align-center my-info__first__branch mt-1",
                          },
                          [_vm._v(_vm._s(_vm.userProfile.department))]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "view-all-detail py-3",
          class: [{ "view-all-detail-ie": _vm.$isIE }],
        },
        [
          _c("div", { staticClass: "view-all-detail__header" }, [
            _c("span", { staticClass: "IE-align-center" }, [
              _vm._v(_vm._s(_vm.$t("forums.newsletter_content"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.$auth.loggedIn
            ? _c(
                "nuxt-link",
                {
                  staticClass: "my-question",
                  class: [{ selected: _vm.isMyQuestion }],
                  attrs: { to: _vm.toMyRequest() },
                },
                [
                  _c("IcQuestionOutline"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("forums.my_question")))]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "wrapper-category" }, [
            _c(
              "div",
              {
                staticClass: "my-category",
                class: {
                  selected: _vm.getCategories.length && !_vm.isMyQuestion,
                },
                on: { click: _vm.toMyForum },
              },
              [
                _c("span", { staticClass: "IE-align-center" }, [
                  _vm._v(_vm._s(_vm.$t("forums.topic"))),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "category",
                class: [
                  { "d-none": !_vm.getCategories.length || _vm.isMyQuestion },
                ],
              },
              _vm._l(_vm.getCategories, function (category) {
                return _c(
                  "div",
                  {
                    key: category.id,
                    staticClass: "category__item d-flex",
                    class: [
                      {
                        active:
                          _vm.selectedId === category.id && !_vm.isMyQuestion,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.selectCategory(category)
                      },
                    },
                  },
                  [
                    _vm.$isIE
                      ? _c("div", {
                          staticClass: "img-fluid-IE",
                          style: `background: url(${category.banner}) no-repeat center;`,
                        })
                      : _c("nuxt-img", {
                          staticClass: "img-fluid",
                          attrs: {
                            format: "webp",
                            width: "32",
                            height: "32",
                            src: category.banner,
                            alt: "",
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              delay: [1000, 200],
                              placement: "bottom-start",
                              animateFill: false,
                              animation: "scale",
                              zIndex: _vm.textContent ? -1 : 9999,
                            },
                            expression:
                              "{\n              delay: [1000, 200],\n              placement: 'bottom-start',\n              animateFill: false,\n              animation: 'scale',\n              zIndex: textContent ? -1 : 9999,\n            }",
                          },
                        ],
                        staticClass: "_content ml-2 flex-1",
                        attrs: {
                          id: "menu-category-qa-" + category.id,
                          content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${category.name}</span>`,
                        },
                        on: {
                          contextmenu: function ($event) {
                            return (() => {
                              _vm.textContent = ""
                            }).apply(null, arguments)
                          },
                          mouseover: () => {
                            _vm.textContent = _vm.$handleHover(
                              `time-new-posts-${category.id}`,
                              category.name,
                              2,
                              22,
                              0,
                              null
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "IE-align-center",
                            class: [{ "d-block": _vm.$isIE }],
                          },
                          [_vm._v(_vm._s(category.name))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.$auth.loggedIn
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isMyQuestion,
                        expression: "!isMyQuestion",
                      },
                    ],
                    staticClass: "add-category",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("add-category")
                      },
                    },
                  },
                  [
                    _c("IcPlus"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("forums.add_category")))]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }