var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "group-question flex-center w-100 h-100",
      attrs: { tabindex: "0" },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.closeAddQuestion.apply(null, arguments)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeAddQuestion.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "box-add-question",
          class: [{ "waiting-upload": _vm.isWaitingUpload }],
          on: { drop: _vm.onDrop },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value:
                    !_vm.$isiOS ||
                    _vm.currentComponent !==
                      _vm.conponentGeneral.CHOOSE_CATEGORY,
                  expression:
                    "!$isiOS || currentComponent !== conponentGeneral.CHOOSE_CATEGORY",
                },
              ],
              ref: "box_add_question",
              staticClass: "wrapper-add-question pt-4",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "wrapper-add-question__header d-flex align-items-center px-4",
                },
                [
                  _c("div", { staticClass: "_title flex-1" }, [
                    _c("span", [_vm._v(_vm._s(_vm.getTitle))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn__close",
                      on: {
                        click: function ($event) {
                          _vm.currentComponent ===
                          _vm.conponentGeneral.ADD_QUESTION_DETAIL
                            ? _vm.closeAddQuestion()
                            : _vm.backTo()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("assets/icons/icon-close.svg"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "divided my-3" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "wrapper-add-question__body" },
                [
                  _c("ValidationObserver", {
                    ref: "obs_question",
                    attrs: { tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ valid }) {
                          return [
                            _c(
                              "perfect-scrollbar",
                              {
                                ref: "ps",
                                staticClass:
                                  "wrapper-add-question__body__content-image",
                              },
                              [
                                _c(
                                  _vm.currentComponent,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "component",
                                        tag: "component",
                                        staticClass: "mt-3",
                                        model: {
                                          value: _vm.question,
                                          callback: function ($$v) {
                                            _vm.question = $$v
                                          },
                                          expression: "question",
                                        },
                                      },
                                      "component",
                                      _vm.vBindValue,
                                      false
                                    ),
                                    _vm.vOnListener
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "file-wrapper" },
                                  [
                                    _vm.currentComponent !==
                                    _vm.conponentGeneral.CHOOSE_CATEGORY
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "add-image d-flex align-items-center justify-content-between",
                                            class: {
                                              "pe-none": _vm.isWaitingUpload,
                                            },
                                            on: { click: _vm.chooseFile },
                                          },
                                          [
                                            _c("div", { staticClass: "des" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "IE-align-center paragraph",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "forums.add_new_images_question"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("base-upload-image", {
                                              ref: "base-upload",
                                              attrs: {
                                                compressorQuality: 0.3,
                                                limitImage: 10,
                                                currentImage:
                                                  _vm.question.images.length,
                                              },
                                              on: {
                                                "before-upload-image":
                                                  function ($event) {
                                                    _vm.isWaitingUpload = true
                                                  },
                                                "upload-image-success":
                                                  _vm.uploadImageSuccess,
                                                "upload-image-error":
                                                  _vm.uploadImageError,
                                                "limit-upload-image":
                                                  _vm.UploadLimitImage,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      UploadImage,
                                                    }) {
                                                      return [
                                                        _c("input", {
                                                          staticClass: "d-none",
                                                          attrs: {
                                                            type: "file",
                                                            name: "file_multiple",
                                                            autocomplete: "off",
                                                            id: "add_image_question",
                                                            accept:
                                                              "image/*,.heic",
                                                            multiple: "",
                                                          },
                                                          on: {
                                                            change: UploadImage,
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("grid-gallery-image", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.question.images.length &&
                                            _vm.currentComponent ===
                                              _vm.conponentGeneral
                                                .ADD_QUESTION_DETAIL,
                                          expression:
                                            "question.images.length && currentComponent === conponentGeneral.ADD_QUESTION_DETAIL",
                                        },
                                      ],
                                      staticClass: "mb-3 mt-3",
                                      attrs: { images: _vm.question.images },
                                      on: {
                                        "on-delete": _vm.deleteImage,
                                        "on-delete-detail":
                                          _vm.deleteImageDetail,
                                        "on-edit": _vm.editImage,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "icons-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "icon-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.chooseFile.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("IcImage"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [_vm._v("Images")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "icon-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.chooseFile.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("IcVideo"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [_vm._v("Videos")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.currentComponent !==
                            _vm.conponentGeneral.CHOOSE_CATEGORY
                              ? _c("div", { staticClass: "area-btn-save" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-save btn_system_action_primary_hover paragraph",
                                      attrs: {
                                        disabled:
                                          _vm.currentComponent !==
                                            _vm.conponentGeneral
                                              .EDIT_IMAGE_DETAIL &&
                                          (!valid || _vm.validateSpace()),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.editOrNot
                                            ? _vm.EditQuestion()
                                            : _vm.addQuestion()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.currentComponent ===
                                              _vm.conponentGeneral
                                                .EDIT_IMAGE_DETAIL
                                              ? _vm.$t("common.next")
                                              : _vm.editOrNot
                                              ? _vm.$t("common.save")
                                              : _vm.$t("forums.add_question")
                                          )
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", { staticClass: "area-btn-save" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-save btn_system_action_primary_hover paragraph",
                                      attrs: { disabled: !valid },
                                      on: { click: _vm.addTopic1 },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.$t("common.next")) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showErrorDialog
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showErrorDialog,
                  expression: "showErrorDialog",
                },
              ],
            },
            [
              _c("dialog-error", {
                attrs: {
                  title: _vm.$t("common.error"),
                  content: _vm.messageError,
                  button: _vm.$t("common.ok"),
                },
                on: {
                  "click-yes": function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.handleYes.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmDialog
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmDialog,
                  expression: "showConfirmDialog",
                },
              ],
            },
            [
              _c("dialog-confirm", {
                attrs: {
                  title: _vm.titleConfirm,
                  content: _vm.messageError,
                  isButtonNo: false,
                },
                on: {
                  "click-no": function ($event) {
                    _vm.showConfirmDialog = false
                  },
                  "click-yes": function ($event) {
                    _vm.showConfirmDialog = false
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmCloseDialog
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmCloseDialog,
                  expression: "showConfirmCloseDialog",
                },
              ],
            },
            [
              _c("dialog-confirm", {
                attrs: {
                  title: _vm.$t("receipt.comfirm"),
                  content: _vm.$t("forums.confirm_close_popup"),
                },
                on: {
                  "click-no": function ($event) {
                    _vm.showConfirmCloseDialog = false
                    _vm.focusQuestion()
                  },
                  "click-yes": function ($event) {
                    _vm.$emit("close")
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }