var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    {
      ref: "menu",
      staticClass: "wrapper-menu-right",
      attrs: {
        options: { wheelPropagation: _vm.isWheelPropagation },
        "watch-options": true,
        tag: "div",
      },
    },
    [
      _c("div", { staticClass: "wrapper_tag" }, [
        _c("div", { staticClass: "wrapper_tag__header" }, [
          _c("div", { staticClass: "wrapper_tag__header--name" }, [
            _c("span", { staticClass: "IE-align-center" }, [
              _vm._v(_vm._s(_vm.$t("forums.popular_hashtag"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wrapper_tag__body" },
          _vm._l(_vm.hashTags, function (_data) {
            return _c(
              "nuxt-link",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: {
                      delay: [1000, 200],
                      placement: "bottom-start",
                      animateFill: false,
                      animation: "scale",
                      zIndex: _vm.textContent === "" ? -1 : 9999,
                    },
                    expression:
                      "{\n          delay: [1000, 200],\n          placement: 'bottom-start',\n          animateFill: false,\n          animation: 'scale',\n          zIndex: textContent === '' ? -1 : 9999,\n        }",
                  },
                ],
                key: _data.id,
                staticClass: "wrapper_tag__body--item cursor-pointer",
                attrs: {
                  id: "most-hashtag-" + _data.id,
                  to: _vm.gotoHashTag(_data),
                  content: `<span style='word-break: break-word; white-space: pre-line;'>${_data.name}</span>`,
                  prefetch: "",
                },
                nativeOn: {
                  contextmenu: function ($event) {
                    return (() => {
                      _vm.textContent = ""
                    }).apply(null, arguments)
                  },
                  mouseover: function ($event) {
                    return (() => {
                      _vm.textContent = _vm.$handleHover(
                        `most-hashtag-${_data.id}`,
                        _data.name,
                        1,
                        16.5,
                        16,
                        null
                      )
                    }).apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(`#` + _data.name) + "\n      ")]
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper_featured" }, [
        _c("div", { staticClass: "wrapper_featured__header" }, [
          _c("div", { staticClass: "wrapper_featured__header--name" }, [
            _c("span", { staticClass: "IE-align-center" }, [
              _vm._v(_vm._s(_vm.$t("forums.featured_question"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wrapper_featured__body" },
          _vm._l(_vm.topQuestions, function (ques, index) {
            return _c("question-featured-item", {
              key: ques.id,
              staticClass: "wrapper_featured__body--item",
              class: {
                "d-four-item-mobile": index > 3,
                "item-last-mobile": index === 3,
              },
              attrs: { question: ques },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("to-more-info", { staticClass: "mt-3 responsive_mobile" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }