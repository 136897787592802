
import {
  Component,
  mixins,
  namespace,
  Prop,
  Watch,
} from "nuxt-property-decorator";
import CommonMixins from "~/mixins/CommonMixins.vue";
import { Forum, Notification, UserReport } from "~/modals";
import debounce from "debounce";
import { plainToClass } from "class-transformer";
import IcShare from "~/assets/icons/IcShare.vue";

const NotificationStore = namespace("notification");

const SUBSTRING_LINE_READMORE = 310;
const SUBSTRING_LINE_READMORE_MOBILE = SUBSTRING_LINE_READMORE / 2;
var timeoutDate: NodeJS.Timeout;

@Component({
  components: {
    GridGalleryImage: () => import("~/components/forums/GridGalleryImage.vue"),
    ShareSocialNetwork: () =>
      import("~/components/forums/ShareSocialNetwork.vue"),
    MoreOptionOwner: () => import("~/components/forums/MoreOptionOwner.vue"),
    FullModal: () => import("~/components/FullModal.vue"),
    ViewComment: () => import("~/components/forums/ViewComment.vue"),
    DialogConfirm: () => import("~/components/DialogConfirmWithNoTitle.vue"),
    ConfirmDialog: () => import("~/components/DialogConfirm.vue"),
    DialogError: () => import("~/components/DialogError.vue"),
    HashTagQuestion: () => import("~/components/forums/HashTagQuestion.vue"),
    ReadMoreContent2: () => import("~/components/forums/ReadMoreContent2.vue"),
    EditQuestion: () => import("~/components/forums/EditQuestion.vue"),
    AddQuestion: () => import("~/components/forums/AddQuestion.vue"),
    ReportDetail: () => import("~/components/report/ReportDetail.vue"),
    IcUpvote: () => import("~/assets/icons/IcUpvote.vue"),
    IcDownvote: () => import("~/assets/icons/IcDownvote.vue"),
    IcUpvoteFullFill: () => import("~/assets/icons/IcUpvoteFullFill.vue"),
    IcDownvoteFullFill: () => import("~/assets/icons/IcDownvoteFullFill.vue"),
    IcShare,
  },
})
export default class ListQuestion extends mixins<CommonMixins>(CommonMixins) {
  @Prop({ type: Object, required: true, default: () => ({}) }) question!: Forum;

  @NotificationStore.State newNotificationOfForum;

  author = {};
  messageConfirm = "";
  showErrorDialog = false;
  errorContent = "";
  showConfirmDialog = false;
  confirmDialogContent = this.$t(
    "authentication.update_profile_warn"
  ).toString();
  labelButtonYes?: string;
  labelButtonNo?: string;
  errCode = "";
  currentTime = +new Date();
  report: UserReport = {};
  showReportDetail = false;
  reportTitle = "";
  checkHideQuestion = false;
  showConfirmHideQuestion = false;
  showConfirmBlockUser = false;
  hideBlockContent = "";
  confirmBlockUserTitle = "";
  checkIsVisible = true;
  // checkShowTooltip = false;
  oldLike = false;
  oldDislike = false;
  isEmit = false;
  isRightClick: boolean = false;
  isShow: boolean = true;
  textContent = "";
  warningLikeQuestion = false;

  created() {
    this.question.liked = this.question.isLiked;
    this.question.disliked = this.question.isDisliked;
    this.oldLike = this.question.isLiked || false;
    this.oldDislike = this.question.isDisliked || false;
  }
  mounted() {
    if (this.$route.path.includes("other-candidates")) {
      this.isShow = false;
    }
    timeoutDate = setInterval(() => {
      this.currentTime = +new Date();
    }, 60000);
    // this.$nextTick(() => {
    //   const ele = document.getElementById(`tag_name_${this.question.id}`);
    //   this.checkShowTooltip = (ele?.offsetWidth || 0) < (ele?.scrollWidth || 0);
    // });
    // this.$nextTick(() => {
    //   const isInViewport = () => {
    //     const rect = (this.$refs['wrapper_question'] as any).getBoundingClientRect();
    //     this.checkIsVisible = rect.bottom > 0;
    //   }
    //   document.addEventListener('scroll', isInViewport);
    // });
  }
  get authorName() {
    return this.question.author?.name ?? "";
  }
  get authorAvatar() {
    return this.question.author?.avatar ?? "";
  }
  @Watch("newNotificationOfForum")
  onAddNotificationOfForum(newVal: Notification, oldVal: Notification) {
    if (!newVal || JSON.stringify(newVal) === JSON.stringify(oldVal)) {
      return;
    }
    if (
      newVal.content === "CREATE_ANSWER_QA" &&
      newVal?.answer?.parentId === undefined
    ) {
      if (this.question.id === newVal?.answer?.questionId) {
        this.question.totalComment = (this.question.totalComment || 0) + 1;
      }
      return;
    }
    if (this.question.id !== newVal.question?.id) {
      return;
    }
    if (newVal.content === "LIKE_QA" || newVal.content === "UNLIKE_QA") {
      if (this.isEmit) {
        this.isEmit = false;
        return;
      }
      if (
        newVal.content === "LIKE_QA" &&
        this.$auth.user.id == (newVal as any).senderID
      )
        this.question.disliked = false;
      this.question.liked =
        this.$auth.user.id == (newVal as any).senderID
          ? newVal.question?.isLiked
          : this.question.liked;
      this.question.totalLike =
        this.question.totalLike !== newVal.question?.totalLike
          ? newVal.question?.totalLike
          : this.question.totalLike;
      this.oldLike = this.question.liked || false;
      this.oldDislike = this.question.disliked || false;
      return;
    }
    if (
      newVal.content === "DISLIKE_QA" ||
      newVal.content === "QUIT_DISLIKE_QA"
    ) {
      if (this.isEmit) {
        this.isEmit = false;
        return;
      }
      if (
        newVal.content === "DISLIKE_QA" &&
        this.$auth.user.id == (newVal as any).senderID
      )
        this.question.liked = false;
      this.question.disliked =
        this.$auth.user.id == (newVal as any).senderID
          ? newVal.question?.isDisliked
          : this.question.disliked;
      this.question.totalLike =
        this.question.totalLike !== newVal.question?.totalLike
          ? newVal.question?.totalLike
          : this.question.totalLike;
      this.oldLike = this.question.liked || false;
      this.oldDislike = this.question.disliked || false;
      return;
    }
  }
  get tagName() {
    // const arrFilter = this.filteredArray(this.cleanSource(this.$store.state.tag.tags))?.filter(
    //     _o => this.question.tagId === _o.id) ?? [];
    // return arrFilter;

    const arrFilter =
      this.filteredArrayLanguageCode(
        this.cleanSource(this.$store.state.tag.tags),
        this.$i18n.locale
      )?.filter((_o) => this.question.tagId === _o.id) ?? [];
    return arrFilter;
  }
  handleCloseModal(e) {}
  likeQuestion(e) {
    if (!this.$auth.loggedIn) {
      // this.question.warningLikeQuestion = !this.$auth.loggedIn;
      this.warningLikeQuestion = !this.$auth.loggedIn;
      this.messageConfirm = this.$t("forums.please_sign_in_to_vote").toString();
      return;
    }
    const hasLike = this.question.liked;
    this.question.liked = !this.question.liked;
    const currentTotalLike = this.question.totalLike;
    if (this.question.disliked) {
      this.question.disliked = false;
      this.question.totalLike = (this.question.totalLike || 0) + 2;
    } else if (hasLike) {
      this.question.totalLike = (this.question.totalLike || 0) - 1;
    } else this.question.totalLike = (this.question.totalLike || 0) + 1;
    // this.question.totalLike = this.question.liked ? (this.question.totalLike || 0) + 1 : (this.question.totalLike || 0) - 1;
    this.likeQuestion1(currentTotalLike);
    // return debounce(async (e) => {
    //   try {
    //     if (hasLike !== this.question.liked) {
    //       const _rs = await this.$axios.$patch(`api/v1/communities/${this.question.id}/like`);
    //       // this.question.liked = !this.question.liked;
    //       // this.question.totalLike = this.question.liked ? (this.question.totalLike || 0) + 1 : (this.question.totalLike || 0) - 1;
    //     }
    //   } catch (error) {
    //     //TODO
    //     this.question.liked = hasLike;
    //     this.question.totalLike = currentTotalLike;
    //     this.$handleErrorApi(error, (errCode) => {
    //       this.showErrorDialog = true;
    //       this.errorContent = this.$t(`error.${errCode}`).toString();
    //       this.errCode = errCode;
    //     });
    //   }
    // }, 200).call(this);
  }
  likeQuestion1 = debounce(this.likeQuestionDebounce, 500).bind(this);
  async likeQuestionDebounce(currentTotalLike) {
    if (this.oldLike) {
      if (this.question.disliked) {
        //call dislike
        this.onDownVote(currentTotalLike);
        return;
      } else if (!this.question.liked) {
        //call unlike
        this.onUpvote(currentTotalLike);
        return;
      }
    } else if (this.oldDislike) {
      if (this.question.liked) {
        //call like
        this.onUpvote(currentTotalLike);
        return;
      }
      if (!this.question.disliked) {
        //call un dislike
        this.onDownVote(currentTotalLike);
        return;
      }
    } else if (!this.oldDislike && !this.oldLike) {
      if (this.question.liked) {
        this.onUpvote(currentTotalLike);
        return;
      }
      if (this.question.disliked) {
        this.onDownVote(currentTotalLike);
        return;
      }
    }
  }
  async onUpvote(currentTotalLike) {
    try {
      this.isEmit = true;
      this.oldDislike = this.question.disliked || false;
      this.oldLike = this.question.liked || false;
      const _rs = await this.$axios.$patch(
        `api/v1/communities/${this.question.id}/like`
      );
    } catch (error) {
      this.isEmit = false;
      this.question.liked = this.oldLike;
      this.question.disliked = this.oldDislike;
      this.question.totalLike = currentTotalLike;
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
        this.errCode = errCode;
      });
    }
  }
  async onDownVote(currentTotalLike) {
    try {
      this.isEmit = true;
      this.oldDislike = this.question.disliked || false;
      this.oldLike = this.question.liked || false;
      const _rs = await this.$axios.$patch(
        `api/v1/communities/${this.question.id}/dislike`
      );
      return;
    } catch (error) {
      this.isEmit = false;
      this.question.liked = this.oldLike;
      this.question.disliked = this.oldDislike;
      this.question.totalLike = currentTotalLike;
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
        this.errCode = errCode;
      });
    }
  }
  downVote(e) {
    if (!this.$auth.loggedIn) {
      // this.question.warningLikeQuestion = !this.$auth.loggedIn;
      this.warningLikeQuestion = !this.$auth.loggedIn;
      this.messageConfirm = this.$t("forums.please_sign_in_to_vote").toString();
      return;
    }
    const hasLike = this.question.disliked;
    this.question.disliked = !this.question.disliked;
    const currentTotalLike = this.question.totalLike;
    if (this.question.liked) {
      this.question.liked = false;
      this.question.totalLike = (this.question.totalLike || 0) - 2;
    } else if (hasLike) {
      this.question.totalLike = (this.question.totalLike || 0) + 1;
    } else this.question.totalLike = (this.question.totalLike || 0) - 1;
    this.likeQuestion1(currentTotalLike);
    // return debounce(async (e) => {
    //   try {
    //     if (hasLike !== this.question.disliked) {
    //       const _rs = await this.$axios.$patch(`api/v1/communities/${this.question.id}/dislike`);
    //       // this.question.liked = !this.question.liked;
    //       // this.question.totalLike = this.question.liked ? (this.question.totalLike || 0) + 1 : (this.question.totalLike || 0) - 1;
    //     }
    //   } catch (error) {
    //     //TODO
    //     this.question.disliked = hasLike;
    //     this.question.totalLike = currentTotalLike;
    //     this.$handleErrorApi(error, (errCode) => {
    //       this.showErrorDialog = true;
    //       this.errorContent = this.$t(`error.${errCode}`).toString();
    //       this.errCode = errCode;
    //     });
    //   }
    // }, 200).call(this);
  }
  // downVote1 = debounce(this.downVoteDebounce, 300).bind(this);
  // async downVoteDebounce(currentTotalLike) {
  //   try {
  //     if (this.oldDislike !== this.question.disliked) {
  //       this.isEmit = true;
  //       const _rs = await this.$axios.$patch(`api/v1/communities/${this.question.id}/dislike`);
  //       this.oldDislike = this.question.liked || false;
  //     }
  //   } catch (error) {
  //     //TODO
  //     this.question.disliked = this.oldDislike;
  //     this.question.totalLike = currentTotalLike;
  //     this.$handleErrorApi(error, (errCode) => {
  //       this.showErrorDialog = true;
  //       this.errorContent = this.$t(`error.${errCode}`).toString();
  //       this.errCode = errCode;
  //     });
  //   }
  // }

  fnReadMore(e) {
    this.$set(this.question, "readMore", !this.question.readMore);
  }

  openSocial(e) {
    this.$set(this.question, "isSocial", !this.question.isSocial);
    // this.$forceUpdate();
    if (!this.question.isSocial) {
      return;
    }
    // const btn_social = this.$el?.querySelector('#btn_social') ?? new Element;
    // this.$nextTick(() => {
    //   let shareSocialNetwork = this.$refs['shareSocialNetwork'];
    //   let share_social_network = this.$el?.querySelector('#share_social_network');
    //   const interval = setInterval(() => {
    //     if (share_social_network && shareSocialNetwork) {
    //       changePositionPopup(btn_social, share_social_network, 'bottom', {offset: [3, -1]});
    //       clearInterval(interval);
    //     } else {
    //       shareSocialNetwork = this.$refs['shareSocialNetwork'];
    //       share_social_network = this.$el?.querySelector('#share_social_network') ?? new Element;
    //     }
    //   }, 10);
    // });
  }
  openMore(e) {
    this.$set(this.question, "isMoreOwner", !this.question.isMoreOwner);
    // this.$forceUpdate();
    if (!this.question.isMoreOwner) {
      return;
    }
    // const btn_more = this.$el?.querySelector('#btn_more') ?? new Element;
    // this.$nextTick(() => {
    //   let moreOptionOwner = this.$refs['moreOptionOwner'];
    //   let more_option_owner = this.$el?.querySelector('#more_option_owner');
    //   const interval = setInterval(() => {
    //     if (more_option_owner && moreOptionOwner) {
    //       changePositionPopup(btn_more, more_option_owner, 'bottom', {offset: [-55, -1]});
    //       clearInterval(interval);
    //     } else {
    //       moreOptionOwner = this.$refs['moreOptionOwner'];
    //       more_option_owner = this.$el?.querySelector('#more_option_owner');
    //     }
    //   }, 10);
    // });
  }
  onCloseSocial() {
    this.question.isSocial = false;
    this.$forceUpdate();
  }
  onCloseMore() {
    this.question.isMoreOwner = false;
    this.$forceUpdate();
  }
  openEdit() {
    this.$emit("edit-question", true);
    this.$set(this.question, "isEditData", !this.question.isEditData); //https://www.telerik.com/blogs/so-what-actually-is-vue-set
  }
  viewCommentOfQuestion(e) {
    if (this.$route.path.includes("other-candidates")) {
      this.isShow = !this.isShow;
    }

    // this.question.isViewComment = !this.question.isViewComment;
    this.$set(this.question, "isViewComment", !this.question.isViewComment);
    (
      (this.$refs.view_comment as Vue)?.$el?.querySelector(
        "#input_answer"
      ) as HTMLElement
    )?.focus();
    if (!this.$auth.loggedIn) {
      // this.question.warningLikeQuestion = true;
      this.warningLikeQuestion = true;
      this.messageConfirm = this.$t(
        "forums.warning_not_logged_in_comment"
      ).toString();
      return;
    }
    if (this.question.isViewComment) {
      this.$nuxt.$emit("show-answers-question");
    }
  }
  goToLogin() {
    // this.question.warningLikeQuestion = false;
    this.warningLikeQuestion = false;
    // this.$nuxt.$emit('openLoginDialog');
    // this.$router.push((this as any).localePath({
    // 	name: "login"
    // }));
    this.redirectLoginToUrl();
  }

  fnYes() {
    this.$router.push(
      (this as any).localePath({
        name: "candidates-create-basic",
        query: { redirect: "back" },
      })
    );
  }
  get removeQuestion() {
    return this.$store.state.report.removeQuestion;
  }
  @Watch("removeQuestion")
  removeQuestionFromStore(newVal, oldVal) {
    if (newVal) {
      this.$emit("remove-question", newVal);
    }
  }
  get languageCode() {
    return this.$auth.loggedIn
      ? this.$auth.user.support_language.code
      : this.$i18n.locale;
  }
  get substringLineReadmore() {
    return this.$device.isMobileOrTablet
      ? SUBSTRING_LINE_READMORE / 2
      : SUBSTRING_LINE_READMORE;
  }
  async onBookmark(e) {
    const currentBookmark = this.question.isBookmark;
    try {
      this.$set(this.question, "isBookmark", !currentBookmark);
      const rs = await this.$axios.$patch(
        `api/v1/communities/${this.question.id}/bookmark`
      );
      // this.$set(this.question, 'isBookmark', !this.question.isBookmark)
    } catch (error) {
      this.$set(this.question, "isBookmark", currentBookmark);
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
      });
    }
  }

  onClickYes(e) {
    if (this.errCode === "ERR_QA0101") {
      this.$emit("remove-question", this.question.id);
    }
    this.showErrorDialog = false;
    e.preventDefault();
    e.stopPropagation();
  }
  beforeDestroy() {
    clearInterval(timeoutDate);
  }
  async onReport(id) {
    if (!this.$auth.loggedIn) {
      this.messageConfirm = this.$t("report.warning_not_logged_in").toString();
      // this.question.warningLikeQuestion = true;
      this.warningLikeQuestion = true;
      return;
    }
    try {
      const result = await this.$axios.$get(
        `/api/v1/reports/detail?objectable_id=${id}&objectable_type=COMMUNITY`
      );
      const reportDetail = result["data"];
      if (reportDetail) {
        this.report = plainToClass(UserReport, reportDetail);
        this.showReportDetail = true;
        this.reportTitle = this.$t("report.you_report_question").toString();
      } else {
        this.$store.dispatch("report/showReport", ["COMMUNITY", id]);
      }
    } catch (error) {
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
      });
    }
  }
  async reportComment(id) {
    if (!this.$auth.loggedIn) {
      this.messageConfirm = this.$t("report.warning_not_logged_in").toString();
      // this.question.warningLikeQuestion = true;
      this.warningLikeQuestion = true;
      return;
    }
    try {
      const result = await this.$axios.$get(
        `/api/v1/reports/detail?objectable_id=${id}&objectable_type=COMMUNITY_ANSWER`
      );
      const reportDetail = result["data"];
      if (reportDetail) {
        this.report = plainToClass(UserReport, reportDetail);
        this.showReportDetail = true;
        this.reportTitle = this.$t("report.you_report_comment").toString();
      } else {
        this.$store.dispatch("report/showReport", ["COMMUNITY_ANSWER", id]);
      }
    } catch (error) {
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
      });
    }
  }
  closeReportDetail() {
    this.showReportDetail = false;
  }
  formatDateTimeForum(time) {
    let timeFormat = this.$moment(Number(time))
      .locale(this.$i18n.locale)
      .format("LLLL");
    return (
      timeFormat.charAt(0).toString().toUpperCase() +
      timeFormat.toString().slice(1)
    );
  }
  async hideQuestion() {
    try {
      const result = await this.$axios.$post(
        `api/v1/communities/${this.question.id}/hide`
      );
      this.showConfirmHideQuestion = false;
      this.hideBlockContent = this.$t(
        "forums.hide_question_content"
      ).toString();
      this.checkHideQuestion = true;

      const checkPositionElement = (this as any).$refs[
        `wrapper_question_${this.question.id}`
      ].getBoundingClientRect().top;
      let top = (this as any).$refs[`wrapper_question_${this.question.id}`]
        .offsetTop;
      if (this.$route.name?.includes("other-candidates")) top = top - 56;
      if (checkPositionElement < 0) {
        window.scrollTo({
          top: top,
          left: 0,
        });
      }
    } catch (error) {
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
      });
    }
  }
  async hideQuestionFromUser() {
    try {
      const result = await this.$axios.$post(
        `api/v1/users/${this.question.authorId}/block`
      );
      this.showConfirmBlockUser = false;
      this.hideBlockContent = this.$t("report.hide_all_content_message")
        .toString()
        .replaceAll("{0}", this.authorName);
      const checkPositionElement = (this as any).$refs[
        `wrapper_question_${this.question.id}`
      ].getBoundingClientRect().top;
      const top = (this as any).$refs[`wrapper_question_${this.question.id}`]
        .offsetTop;
      if (checkPositionElement < 0) {
        window.scrollTo({
          top: top,
          left: 0,
        });
      }
      this.$emit(
        "remove-question-by-author",
        this.question.id,
        this.question.authorId
      );
      this.checkHideQuestion = true;
      if (this.$route.name?.includes("other-candidates")) {
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    } catch (error) {
      this.$handleErrorApi(error, (errCode) => {
        this.showErrorDialog = true;
        this.errorContent = this.$t(`error.${errCode}`).toString();
      });
    }
  }
  onHide() {
    const checkPositionElement = (this as any).$refs[
      `content_${this.question.id}`
    ].getBoundingClientRect().top;
    const top = (this as any).$refs[`content_${this.question.id}`].offsetTop;
    if (checkPositionElement < 0) {
      window.scrollTo({
        top: top,
        left: 0,
      });
    }
  }
  onRemoveQuestion() {
    this.$emit("remove-question", this.question.id);
    if (
      this.hideBlockContent ===
        this.$t("report.hide_all_content_message")
          .toString()
          .replaceAll("{0}", this.authorName) &&
      this.$route.name?.includes("other-candidates")
    ) {
      this.$router.push(this.localePath("/user-block"));
    }
  }
  handleClickUpvote() {
    if (this.$auth.user && !this.$auth.user.property_id) {
      this.confirmDialogContent = this.$t(
        "forums.please_update_profile_to_vote"
      ).toString();
      this.showConfirmDialog = true;
    } else this.likeQuestion();
  }
  beforeRouteLeave(to, from, next) {
    if (!this.confirmLeave()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  }
  confirmLeave() {
    return window.confirm(this.$t("common.unsave").toString());
  }
}
