
import { Vue, Component, mixins, Prop } from "nuxt-property-decorator";
import { ValidationObserver } from 'vee-validate';
import { QuestionForum, Forum } from "~/modals";
import { plainToClass } from "class-transformer";
import CommonMixins from "~/mixins/CommonMixins.vue";
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import IcImage from "~/assets/icons/IcImage.vue";
import IcVideo from "~/assets/icons/IcVideo.vue";

@Component({
	components: {
		ValidationObserver,
		AddQuestionDetail: () => import("~/components/forums/AddQuestionDetail.vue"),
		ChooseCategory: () => import("~/components/forums/ChooseCategory.vue"),
		GridGalleryImage: () => import("~/components/forums/GridGalleryImageAddQuestion.vue"),
		EditImageDetail: () => import("~/components/forums/EditImageDetail.vue"),
		PerfectScrollbar,
		FullModal: () => import("~/components/FullModal.vue"),
		DialogError: () => import("~/components/DialogError.vue"),
		DialogConfirm: () => import("~/components/DialogConfirm.vue"),
		BaseUploadImage: () => import("~/components/forms/BaseUploadImage.vue"),
		IcImage,
		IcVideo
	}
})
export default class AddQuestion extends mixins<CommonMixins>(CommonMixins) {
	@Prop({ type: Boolean, required: false }) editOrNot!: boolean;
	@Prop({type: String, required: false, default: ''}) questionId;

	currentComponent = '';
	question: QuestionForum = new QuestionForum();
	vOnListener = {};
	vBindValue = {};
	conponentGeneral = {
		ADD_QUESTION_DETAIL: 'add-question-detail',
		CHOOSE_CATEGORY: 'choose-category',
		EDIT_IMAGE_DETAIL: 'edit-image-detail'
	}
	firstCategoryName = '';
	showErrorDialog = false;
	messageError = '';
	isWaitingUpload = false;
	showConfirmDialog = false;
	titleConfirm = '';
	interestedTags = [];
	showConfirmCloseDialog = false;
	checkDebounce = true;
	errorCodeResponse: string = '';
	errCode = '';
	oldQuestion: QuestionForum = new QuestionForum();

	async fetch() {
		if(this.editOrNot){
			try {
			const _rs = await this.$axios.$get(`/api/v1/communities/${this.questionId}`);
			this.question = plainToClass(QuestionForum, _rs['data']);
			this.question.isEditData = false;
			this.oldQuestion = JSON.parse(JSON.stringify(this.question));
			} catch (error) {
				this.$handleErrorApi(error, (errCode) => {
					this.showErrorDialog = true;
					this.messageError = this.$t(`error.${errCode}`).toString();
					this.errCode = errCode;
				});
			}
		}
	}

	async created() {
		this.currentComponent = this.conponentGeneral.ADD_QUESTION_DETAIL;
		this.vOnListener = {
			'add-category': this.addCategory
		}
		this.vBindValue = {
			nameCategory: this.firstCategoryName
		}
		const rs = await this.$axios.$get('api/v1/user/interested-tags');
		this.interestedTags = rs['data'];
	}
	mounted() {
		this.$nextTick(() => {
			(this.$refs.modal as any).focus();
		});
	}

	beforeDestroy(){
	}

	get categories() {
		const _tags = this.filteredArrayLanguageCode(this.cleanSource(this.$store.state.tag.generalTags), this.$i18n.locale);
		return _tags.filter(_o => this.interestedTags.some(id => id === _o.id));
	}
	addCategory() {
		this.currentComponent = this.conponentGeneral.CHOOSE_CATEGORY;
		this.vOnListener = {
			'add': (...agrs) => {
				this.question.tagId = agrs[0];
				this.$watch(() => this.question.tagId, (newVal, oldVal) => {
					if (!newVal) {
						return;
					}
					this.firstNameOther();
					this.validateSpace();
				}, { immediate: true });
				this.firstCategoryName = agrs[1];
				this.question.hashTags = [];
				this.currentComponent = this.conponentGeneral.ADD_QUESTION_DETAIL;
				this.vOnListener = {
					'add-category': this.addCategory
				};
				this.focusQuestion();
			},
		}
		this.vBindValue = {
			tagIds: this.question.tagId,
			categories: this.categories
		}
	}

	get checkEdit() {
		return JSON.stringify(this.oldQuestion) === JSON.stringify(this.question);
	}

	addTopic1(el){
		(this.$refs.component as any).addTopic()
	}
	backTo() {
		this.currentComponent = this.conponentGeneral.ADD_QUESTION_DETAIL;
		this.vOnListener = {
			'add-category': this.addCategory
		}
	}
	chooseFile(e) {
		document.getElementById('add_image_question')?.click();
	}
	uploadImageSuccess(images: []) {
		this.isWaitingUpload = false;
		this.question.images?.push(...images);
		this.scrollToView();
	}
	scrollToView() {
		this.$nextTick(() => {
			document.getElementById('scroll-to-image')?.scroll({
				behavior: "smooth",
				top: document.getElementById('scroll-to-image')?.scrollHeight
			});
		});
	}
	uploadImageError(message) {
		this.isWaitingUpload = false;
		this.messageError = message;
		this.showErrorDialog = true;
	}
	UploadLimitImage(message) {
		this.isWaitingUpload = false;
		this.titleConfirm = message.title;
		this.messageError = message.message;
		this.showConfirmDialog = true;
	}
	deleteImage(_url) {
		this.question.images?.forEach(async (url) => {
			try {
				const _rs = await this.$axios.delete("api/v1/storages", {
					params: {
						'url': url
					}
				});
				const idx = this.question.images?.indexOf(url) ?? -1;
				if (idx >= 0) {
					this.question.images?.splice(idx, 1);
				}
			} catch (error) {
				this.$handleErrorApi(error, (errCode) => {
					this.showErrorDialog = true;
					this.messageError = this.$t(`error.${errCode}`).toString();
				});
			}
		});
	}
	async deleteImageDetail(_url) {
		try {
			const _rs = await this.$axios.delete("api/v1/storages", {
				params: {
					'url': _url
				}
			});
			const idx = this.question.images?.indexOf(_url) ?? -1;
			if (idx >= 0) {
				this.question.images?.splice(idx, 1);
			}
		} catch (error) {
			this.$handleErrorApi(error, (errCode) => {
				this.showErrorDialog = true;
				this.messageError = this.$t(`error.${errCode}`).toString();
			});
		}
	}
	editImage() {
		this.currentComponent = this.conponentGeneral.EDIT_IMAGE_DETAIL;
		this.$nextTick(() => {
			(this.$refs["box_add_question"] as any).scrollTop = 0;
		})
		this.vOnListener = {
			'on-delete-detail': url => {
				this.deleteImageDetail(url);
			}
		}
		this.vBindValue = {
			images: this.question.images
		}
	}
	async addQuestion() {
		try {
			if (!this.checkDebounce) {
				return;
			}
			this.checkDebounce = false;
			if (this.currentComponent === this.conponentGeneral.EDIT_IMAGE_DETAIL) {
				this.currentComponent = this.conponentGeneral.ADD_QUESTION_DETAIL;
				this.vOnListener = {
					'add-category': this.addCategory
				}
				this.checkDebounce = true;
				return;
			}
			const valid = (this.$refs['obs_question'] as any).validate();
			if (valid) {
				const rs = await this.$axios.$post(`/api/v1/communities`, {
					"title": this.question.title?.trim(),
					"content": this.question.content?.trim(),
					"hashtag_ids": this.question.hashTags,
					"image_urls": this.question.images,
					"tag_id": this.question.tagId
				});
				const question = plainToClass(Forum, rs['data']);
				const author = await this.$store.dispatch('profile/fetchNewProfileById', question.authorId);
				question.author = author;
				this.$emit('add-question-success', question);
			}
		} catch (error) {
			this.$handleErrorApi(error, (errCode) => {
				this.checkDebounce = true;
				this.showErrorDialog = true;
				this.errorCodeResponse = errCode;
				this.messageError = this.$t(`error.${errCode}`).toString();
			});
		}
	}
	async EditQuestion() {
		try {
			if (this.currentComponent === this.conponentGeneral.EDIT_IMAGE_DETAIL) {
				this.currentComponent = this.conponentGeneral.ADD_QUESTION_DETAIL;
				this.vOnListener = {
					'add-category': this.addCategory
				}
				return;
			}
			const valid = (this.$refs['obs_question'] as any).validate();
			if (valid) {
				const rs = await this.$axios.$put(`/api/v1/communities/${this.question.id}`, {
					"title": this.question.title?.trim(),
					"content": this.question.content?.trim(),
					"hashtag_ids": this.question.hashTags,
					"image_urls": this.question.images,
					"tag_id": this.question.tagId
				});
        const question = plainToClass(Forum, rs['data']);
        const author = await this.$store.dispatch('profile/fetchNewProfileById', question.authorId);
        question.author = author;
				this.$emit('edit-question-success', question);
			}
		} catch (error) {
			this.$handleErrorApi(error, (errCode) => {
				this.showErrorDialog = true;
				this.messageError = this.$t(`error.${errCode}`).toString();
			});
		}
	}

	firstNameOther() {
		if (!this.question.tagId) {
			return false;
		}
		const other = this.filteredArrayLanguageCode(this.cleanSource(this.$store.state.tag.tags), 'en')
			.find(_o => _o.id === this.question.tagId);
		if (other.name.toString().toUpperCase() === 'OTHER' || other.name.toString() === 'Other') {
			return true
		}
		return false;
	}
	checkValid(){
		
	}

	validateSpace() {
		if(this.checkEdit && this.editOrNot) return true;
		if (this.firstNameOther()) {
			return !this.question.title?.trim() || !this.question.content?.trim() || !this.question.tagId;
		}
		return !this.question.title?.trim() || !this.question.content?.trim() || !this.question.hashTags.length;
	}
	focusQuestion() {
		this.$nextTick(() => {
			(this.$refs.modal as any).focus();
		});
	}
	closeAddQuestion() {
		if (this.checkEdit && this.editOrNot) {
			this.$emit('close');
		}
		if (this.question.title?.trim() || this.question.content?.trim() || this.question.hashTags.length || this.question.tagId || this.question.images?.length) {
			this.showConfirmCloseDialog = true;
		} else{ 
			this.$emit('close');
		}
	}
	onDrop(e) {
		e.stopPropagation();
		e.preventDefault();
		(this.$refs['base-upload'] as any)?.pickFile(e);
	}
	handleYes() {
		if (this.errorCodeResponse == 'ERR_ANS0102') {
			this.$emit('close');
			this.showErrorDialog = false;
		}
		else {
			this.showErrorDialog = false;
		}
	}
	get getTitle() {
		switch (this.currentComponent) {
			case this.conponentGeneral.EDIT_IMAGE_DETAIL:
				return this.editOrNot ? this.$t('common.create_posts_avatar') : this.$t('common.create_posts_avatar').toString();
			case (this.conponentGeneral.CHOOSE_CATEGORY):
				return this.editOrNot ? this.$t('forums.edit_question') : this.$t('forums.add_category').toString();
			default:
				return this.editOrNot ? this.$t('forums.edit_question') : this.$t('forums.create_question').toString();
		}
	}
}
