
import { Component, mixins, Prop, Watch } from "nuxt-property-decorator";
import CommonMixins from "~/mixins/CommonMixins.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import ViewAvatarUser from "~/components/ViewAvatarUser.vue";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { Profile } from "~/modals";
import IcQuestionOutline from "~/assets/icons/IcQuestionOutline.vue";
import IcPlus from "~/assets/icons/IcPlus.vue";

@Component({
  components: {
    PerfectScrollbar,
    ViewAvatarUser,
    IcQuestionOutline,
    IcPlus,
    // ViewAvatarUser: () => import("~/components/ViewAvatarUser.vue"),
  },
})
export default class MenuCategory extends mixins<CommonMixins>(CommonMixins) {
  @Prop({ type: Array, required: true, default: () => [] }) categoryIds;
  selectedId = "";
  isWheelPropagation = true;
  userProfile: Profile = new Profile();
  textContent = "";

  async created() {
    if (!this.$auth.loggedIn) {
      return;
    }
    this.userProfile = await this.$store.dispatch(
      "profile/fetchNewProfileById",
      this.$auth.user.id
    );
  }
  changeWheelPropagation() {
    this.$nextTick(() => {
      const chats = this.$refs["menu"] as Vue;
      const scrollbarYActive = (chats as any)?.ps?.scrollbarYActive ?? false;
      this.isWheelPropagation == scrollbarYActive &&
        (this.isWheelPropagation = !scrollbarYActive);
    });
  }
  mounted() {
    this.changeWheelPropagation();
    this.selectedId = this.$route.query.tag_ids as string;
  }
  updated() {
    this.changeWheelPropagation();
  }
  @Watch("$route.query")
  async onChangeQuery(newVal, oldVal) {
    this.selectedId = (newVal.tag_ids as string) || "";
  }
  get isMyQuestion() {
    return this.$route.name?.includes("my-question");
  }
  get getCategories() {
    const categories = this.filteredArrayLanguageCode(
      this.cleanSource(this.$store.state.tag.generalTags),
      this.$i18n.locale
    );

    // const _language = this.$auth.loggedIn ? this.$auth.user.support_language.code : this.$i18n.locale;
    // categories.unshift({id: '', name: this.$t('common.see_all', _language).toString()});
    if (!this.$auth.loggedIn) {
      return categories;
    }
    // const cloneArray = categories.filter(_o => this.categoryIds.some(id => id === _o.id) || !_o.id);
    // const cloneArray = this.filteredArray([...this.categories]);
    // return cloneArray;
    let arrFilter = [];
    this.categoryIds?.forEach((id) => {
      let hashTagFilter = categories?.find((_o) => _o.id === id);
      if (hashTagFilter) {
        (arrFilter as any).push(hashTagFilter);
      }
    });
    return arrFilter;
  }
  async selectCategory(category) {
    this.selectedId = category.id;
    await this.$router.push(
      (this as any).localePath({
        name: "forums",
        params: this.$route.params,
        query: { ...this.$route.query, tag_ids: category.id },
      })
    );
    // this.$emit('selected-category');
  }
  toMyRequest(e) {
    return (this as any).localePath("forums-my-question");
  }
  toMyForum(e) {
    this.$router.push((this as any).localePath("forums"));
  }
  goToPost(e) {
    this.$router.push((this as any).localePath("posts"));
  }
  goToNews(e) {
    this.$router.push((this as any).localePath("news"));
  }
  onGotoVisa(e) {
    this.$router.push((this as any).localePath("visa-list"));
  }
}
